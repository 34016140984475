import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';

import BuildIcon from '@mui/icons-material/Build';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.viemme2.it/">
	  	https://www.viemme2.it/
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Album() {
	return (
    	<ThemeProvider theme={theme}>
      		<CssBaseline />
      		<AppBar position="relative">
				<Toolbar>
					<BuildIcon sx={{ mr: 2 }} />
					<Typography variant="h7" color="inherit" noWrap>
						Stampi Viemme Due S.r.l.
					</Typography>
				</Toolbar>
      		</AppBar>
    		<main>
				<Container sx={{ py: 8 }} maxWidth="false">
					<Grid container spacing={2}>

						<Grid item xs={3}>
							<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
								<CardMedia
									component="img"
									image="./static/imgs/lettino900.png"
									alt="frigo"
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography gutterBottom variant="h7" component="div">
										Stampo Lettino 900
									</Typography>
									<Typography align='left' variant="body2" color="text.secondary">
										Peso lettino circa 9,5Kg
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small" href="./static/pdf/stampi/LETTINO 900.pdf">Ingrandisci</Button>
								</CardActions>
							</Card>
						</Grid>

						<Grid item xs={3}>
							<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
								<CardMedia
									component="img"
									image="./static/imgs/secchiello.png"
									alt="frigo"
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography gutterBottom variant="h7" component="div">
										Stampo Barca e Secchiello
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small" href="./static/pdf/stampi/STAMPI GIOCATTOLI.pdf">Ingrandisci</Button>
								</CardActions>
							</Card>
						</Grid>
						
						<Grid item xs={3}>
							<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
								<CardMedia
									component="img"
									image="./static/imgs/stampi-sedili.png"
									alt="frigo"
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography gutterBottom variant="h7" component="div">
										Stampi Sedili e schienali per sedie Comunità
									</Typography>
									<Typography align='left' variant="body2" color="text.secondary">
										Misure 32x32 cm - 36x36 cm - 39x39 cm
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small" href="./static/pdf/stampi/STAMPI SEDILI E SCHIENALI X SEDIE COMUNITA.pdf">Ingrandisci</Button>
								</CardActions>
							</Card>
						</Grid>

						<Grid item xs={3}>
							<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
								<CardMedia
									component="img"
									image="./static/imgs/stampo-tavolemaxi.png"
									alt="frigo"
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography gutterBottom variant="h7" component="div">
										Stampo tavole MAXI - diverse misure
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small" href="./static/pdf/stampi/TAVOLE MAXI MISURE DIVERSE DIC 2021.pdf">Ingrandisci</Button>
								</CardActions>
							</Card>
						</Grid>

						<Grid item xs={3}>
							<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
								<CardMedia
									component="img"
									image="./static/imgs/tavola-componibile.png"
									alt="frigo"
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography gutterBottom variant="h7" component="div">
										Stampo tavole componibili con modulo da 50cm
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small" href="./static/pdf/stampi/TAVOLE COMPONIBILI CON MODULO DA 50 CM.pdf">Ingrandisci</Button>
								</CardActions>
							</Card>
						</Grid>

						<Grid item xs={3}>
							<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
								<CardMedia
									component="img"
									image="./static/imgs/telaio-componibile.png"
									alt="frigo"
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography gutterBottom variant="h7" component="div">
										Stampo telaio componibile
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small" href="./static/pdf/stampi/TELAIO COMPONIBILE.pdf">Ingrandisci</Button>
								</CardActions>
							</Card>
						</Grid>

						<Grid item xs={3}>
							<Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
								<CardMedia
									component="img"
									image="./static/imgs/contenitori.png"
									alt="frigo"
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography gutterBottom variant="h7" component="div">
										Nr 20 stampi - contenitori per pasticceria e gelateria
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small" href="./static/pdf/stampi/CONTENITORI X PASTICCERIA E GELATERIA dic 2021.pdf">Ingrandisci</Button>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</Container>
      		</main>
			{/* Footer */}
			<Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
				<Copyright />
			</Box>
			{/* End footer */}
			</ThemeProvider>
		);
}